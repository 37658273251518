.report-filter {
    transition: all .15s;
    .page-header {
        font-weight: 800;
        font-size: 32px;
    }
    .page-sub-header {
        font-weight: 600;
    }
    .btn.btn-link {
        outline: none !important;
        box-shadow: none !important;
    }
}