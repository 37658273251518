html {
  scroll-behavior: smooth;
  /* max-height: 100%; */
}

:root {
	--primary-color: #3C88B5;
	--gradient-color: #2B58A2;
	--primary-active-color: #2E759F;
	--secondary-color: #fff;
	--muted-color: #555;
	--success-color: #1DB91A;
	--dark-color: #000;
	--danger-color: #E50404;
	--white-color: #fff;
	--dark-gray-color: #ccc;
	--darker-gray-color: #9A9A9A;
	--gradient-gray-color: #E6E6E6;
	--light-blue-color: #EDF4FF;
	--lighter-blue-color: #F8FCFE;
}


/******
global 
******/

.main-container {
  position: relative;
  /* overflow: hidden; */
  /* overflow: scroll;
  white-space: nowrap; */
  overflow:auto;
  transition: all .15s;
  // padding: 0 20px;
  // padding-right: 20px;
  max-height: 100vh;
}

.content {
  max-height: 100vh;
  // this is very important to go back to the working version
  display: inline-block;
  padding-left: 16px !important;
  padding-right: 16px !important;

  // width: intrinsic;           /* Safari/WebKit uses a non-standard name */
  // width: -moz-max-content;    /* Firefox/Gecko */
  // width: -webkit-max-content; /* Chrome */
  // width: -moz-fit-content;
  // width: fit-content;

}

.login-container {
  min-height: 100vh;
  min-width: 100%;
}

/********************************
error/success Toast notification
********************************/

.top-left {
	top: 12px;
	left: 12px;
	// transition: transform .6s ease-in;
	animation: toast-in-left .7s;
  position: fixed;
	z-index: 999999
}

.top-right {
	top: 12px;
	right: 12px;
	// transition: transform .6s ease-in-out;
	animation: toast-in-right .7s;
  position: fixed;
	z-index: 999999
}

@keyframes toast-in-right {
	from {
	  transform: translateX(100%);
	  
	}
	to {
	  transform: translateX(0);
	}
}

@keyframes toast-in-left {
	from {
		transform: translateX(-100%);
		
	}
	to {
		transform: translateX(0);
	}
}


// .notification-container {
// 	font-size: 14px;
// 	box-sizing: border-box;
// 	position: fixed;
// 	z-index: 999999
// }

// .top-right {
// 	top: 12px;
// 	right: 12px;
// 	transition: transform .6s ease-in-out;
// 	animation: toast-in-right .7s;
// }

// .bottom-right {
// 	bottom: 12px;
// 	right: 12px;
// 	transition: transform .6s ease-in-out;
// 	animation: toast-in-right .7s;
// }

// .bottom-left {
// 	bottom: 12px;
// 	left: 12px;
// 	transition: transform .6s ease-in;
// 	animation: toast-in-left .7s;
// }

// .notification {
// 	// background: #fff;
// 	transition: .3s ease;
// 	position: relative;
// 	pointer-events: auto;
// 	overflow: hidden;
// 	margin: 0 0 6px;
// 	padding: 30px;
// 	margin-bottom: 15px;
// 	width: 300px;
// 	max-height: 100px;
// 	border-radius: 3px 3px 3px 3px;
// 	box-shadow: 0 0 10px #999;
// 	color: #000;
// 	opacity: .9;
// 	background-position: 15px;
// 	background-repeat: no-repeat;
// }

// .notification:hover {
// 	box-shadow: 0 0 12px #fff;
// 	opacity: 1;
// 	cursor: pointer
// }

// .notification-title {
// 	font-weight: 700;
// 	font-size: 16px;
// 	text-align: left;
// 	margin-top: 0;
// 	margin-bottom: 6px;
//     width: 300px;
//     height: 18px;
// }

// .notification-message {
// 	margin: 0;
// 	text-align: left;
//     height: 18px;
//     margin-left: -1px;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
// }

// .notification-image {
//     float: left;
//     margin-right: 15px;
// }

// .notification-image img {
//     width: 30px;
//     height: 30px;
// }

// .custom-toast {
//     height: 50px;
//     width: 365px;
// 	// color: #fff;
// 	padding: 20px 15px 10px 10px;
// }

// .notification-container button {
// 	position: relative;
// 	right: -.3em;
// 	top: -.3em;
// 	float: right;
// 	font-weight: 700;
// 	color: #fff;
// 	outline: none;
// 	border: none;
// 	text-shadow: 0 1px 0 #fff;
// 	opacity: .8;
// 	line-height: 1;
// 	font-size: 16px;
// 	padding: 0;
// 	cursor: pointer;
// 	background: 0 0;
// 	border: 0
// }

// @keyframes toast-in-right {
// 	from {
// 	  transform: translateX(100%);
	  
// 	}
// 	to {
// 	  transform: translateX(0);
// 	}
// }


/*************************
custom calendar week view
*************************/

// .year {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
// }

// .month {
//   margin: 5px 5px 15px 5px;
// }

// .month-name {
//   color: #ccbe88;
//   font-weight: bold;
// }

// .day {
//   display: inline-block;
//   width: 30px;
//   height: 30px;
//   text-align: center;
//   line-height: 30px;
// }

// .date {
//   width: 30px;
//   height: 30px;
//   background: white;
//   border-radius: 50px;
//   border: none;
//   outline: none;
// }

// .date.in-month:hover {
//   cursor: pointer;
//   background: #ccbe88;
// }

// .today {
//   background: #ccbe88;
// }

// .date.prev-month,
// .date.next-month {
//   color: grey;
// }

/************************************
customizing the sass of the calendar
************************************/

// hide the time display inside each event
.rbc-event-label {
  display: none;
}

// custom design to make all the event cell clickable
.fill-all-event-container {
  height: 100%; 
  width: 100%;
  display: inline-block;
}

.rbc-date-time-gutter {
  position: sticky;
  left: 0;
  background-color: white;
  // border-right: 1px solid #DDD;
  border-left: 1px solid #DDD;
  z-index: 10;
  // margin-right: -1px;
  margin-left: -1px;
  display: flex;
  flex: 1 0;
}

.rbc-date-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  // border-right: 1px solid #DDD;
  // align-items: center;
  justify-content: center;
  font-size: 15px;
}

// update the size of each cell (the 30 min cell)
.rbc-timeslot-group {
  // border-bottom: 1px solid $cell-border;
  // min-height: 70px;
  // display: flex;
  // flex-flow: column nowrap;
  .rbc-time-slot{
    font-size: 15px;
  }
}

.rbc-time-view {
  // display: flex;
  // flex-direction: column;
  // flex: 1;
  // width: 100%;
  border-left: 0;
  // min-height: 0;

  // .rbc-time-gutter {
  //   white-space: nowrap;
  // }

  // hide the all day cell from the display
  .rbc-allday-cell {
    display: none;
  }

  // .rbc-allday-cell {
  //   box-sizing: content-box;
  //   width: 100%;
  //   height: 100%;
  //   position: relative;
  // }
  // .rbc-allday-cell + .rbc-allday-cell {
  //   border-left: 1px solid $cell-border;
  // }

  // .rbc-allday-events {
  //   position: relative;
  //   z-index: 4;
  // }

  // .rbc-row {
  //   box-sizing: border-box;
  //   min-height: 20px;
  // }
}

.rbc-toolbar {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
  // align-items: center;
  // margin-bottom: 10px;
  // font-size: 16px;

  // go back to the previous version
  // max-width: 1000px;

  max-width: 500px;
  position: sticky;
  left: 0;
  // z-index: 10;
  // margin-right: -1px;
  // white-space: nowrap;

  // .rbc-toolbar-label {
  //   flex-grow:1;
  //   padding: 0 10px;
  //   text-align: center;
  // }

  // & button {
  //   color: $btn-color;
  //   display: inline-block;
  //   margin: 0;
  //   text-align: center;
  //   vertical-align: middle;
  //   background: none;
  //   background-image: none;
  //   border: 1px solid $btn-border;
  //   padding: .375rem 1rem;
  //   border-radius: 4px;
  //   line-height: normal;
  //   white-space: nowrap;

  //   &:active,
  //   &.rbc-active {
  //     background-image: none;
  //     box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
  //     background-color: $active-background;
  //     border-color: $active-border;

  //     &:hover,
  //     &:focus {
  //       color: $btn-color;
  //       background-color: darken($btn-bg, 17%);
  //       border-color: darken($btn-border, 25%);
  //     }
  //   }

  //   &:focus {
  //     color: $btn-color;
  //     background-color: $active-background;
  //     border-color: $active-border;
  //   }

  //   &:hover {
  //     color: $btn-color;
  //     background-color: $active-background;
  //         border-color: $active-border;
  //   }
  // }
}

.rbc-time-header-content {
  // flex: 1;
  // display: flex;
  // min-width: 0;
  // flex-direction: column;
  // border-left: 1px solid $cell-border;

  // .rbc-rtl & {
  //   border-left-width: 0;
  //   border-right: 1px solid $cell-border;
  // }

  >.rbc-row.rbc-row-resource {
      // border-bottom: 1px solid $cell-border;
      // flex-shrink: 0;
      background-color: white;
  }
}

// .rbc-toolbar {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 10px;
//   font-size: 16px;
//   max-width: 1000px;
//   position: sticky;
//   left: 0;
//   background-color: white;
//   border-right: 1px solid #DDD;
//   z-index: 10;
//   margin-right: -1px;
//   white-space: nowrap;
// }

.rbc-time-content {
  // display: flex;
  // flex: 1 0 0%;
  // align-items: flex-start;
  // width: 200%;
  // border-top: 2px solid $calendar-border;
  overflow-y: visible;
  // position: relative;

  // > .rbc-time-gutter {
  //   flex: none;
  // }

  // > * + * > * {
  //   border-left: 1px solid $cell-border;
  // }

  // .rbc-rtl & > * + * > * {
  //   border-left-width: 0;
  //   border-right: 1px solid $cell-border;
  // }

  // > .rbc-day-slot {
  //   width: 100%;
  //   user-select: none;
  //   -webkit-user-select: none;
  // }
}

.rbc-time-view-resources {
  .rbc-time-gutter,
  .rbc-time-header-gutter {
  //   position: sticky;
  //   left: 0;
  //   background-color: white;
  // border-right: 1px solid #DDD;

  // uncomment this to go back to previous version
    border-left: 1px solid #DDD;


  //   z-index: 10;
  //   margin-right: -1px;
  }

  .rbc-time-header {
    overflow: visible;
    // freeze the resources at the top
    
    // position: sticky;
    // top: 0;
    // z-index: 10;
    // background-color: white;
  }

  // .rbc-time-header-content {
  //   min-width: auto;
  //   flex: 1 0 0;
  //   flex-basis: 0px;
  // }

  // .rbc-time-header-cell-single-day {
  //   display: none;
  // }

  // width of the tasks column
  .rbc-day-slot {
    min-width: 300px;
  }

  // .rbc-header,
  // .rbc-day-bg {
  //   width: 140px;
  //   // min-width: 0;
  //   flex:  1 1 0;
  //   flex-basis: 0 px;
  // }
}


/*****
popup
*****/

.custom-icon {
  color: #007bff;
  cursor: pointer;
  font-size: 1.75em !important;
}

.custom-icon:hover {
  color: #0069d9;
} 





// .rbc-time-header {
//   display: flex;
//   flex: 0 0 auto; // should not shrink below height
//   flex-direction: row;

//   &.rbc-overflowing {
//     border-right: 1px solid $cell-border;
//   }

//   .rbc-rtl &.rbc-overflowing {
//     border-right-width: 0;
//     border-left: 1px solid $cell-border;
//   }

//   > .rbc-row:first-child {
//     border-bottom: 1px solid $cell-border;
//   }

//   > .rbc-row.rbc-row-resource {
//     border-bottom: 1px solid $cell-border;
//   }

//   // .rbc-gutter-cell {
//   //   flex: none;
//   // }

//   // > .rbc-gutter-cell + * {
//   //   width: 100%;
//   // }
// }

// .rbc-time-header-cell-single-day {
//   display: none;
// }

// .rbc-time-header-content {
//   flex: 1;
//   display: flex;
//   min-width: 0;
//   flex-direction: column;
//   border-left: 1px solid $cell-border;

//   .rbc-rtl & {
//     border-left-width: 0;
//     border-right: 1px solid $cell-border;
//   }

//   >.rbc-row.rbc-row-resource {
//       border-bottom: 1px solid $cell-border;
//       flex-shrink: 0;
//   }
// }

// .rbc-time-content {
//   display: flex;
//   flex: 1 0 0%;
//   align-items: flex-start;
//   width: 100%;
//   border-top: 2px solid $calendar-border;
//   overflow-y: auto;
//   position: relative;

//   > .rbc-time-gutter {
//     flex: none;
//   }

//   > * + * > * {
//     border-left: 1px solid $cell-border;
//   }

//   .rbc-rtl & > * + * > * {
//     border-left-width: 0;
//     border-right: 1px solid $cell-border;
//   }

//   > .rbc-day-slot {
//     width: 100%;
//     user-select: none;
//     -webkit-user-select: none;
//   }
// }

.popover-body {
  min-width: 250px;
}