.primary-checkbox-container {
  display: flex;
  justify-content: left;
  align-content: center;

  .primary-checkbox-holder {
    .form-check-input{
    }
  }

  label {
    font-size: 12px;
    margin: auto;
    margin-left: 20px;
  }
}
