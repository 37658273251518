.primary-radio-container {
  display: flex;
  justify-content: left;
  align-content: center;

  label {
    font-size: 12px;
    margin: auto;
    margin-left: 20px;
  }
}
