.task-schedular-filter {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
    box-shadow: 0 0 10px 0px rgba(0,0,0,0.3);
    background: white;
    .task-schedular-filter-row {
        // padding-left: -1rem !important;
        .btn.btn-link {
            outline: none !important;
            box-shadow: none !important;
        }
    }
}