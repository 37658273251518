.section-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 20px;

    ion-spinner {
        margin-bottom: 10px;
        transform: scale(2);
    }

    p {
        font-size: 24px !important;
    }
}
