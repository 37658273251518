@import "../../../theme/app-variables.scss";

.page-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - (#{$primaryDesktopHeaderHeight}));

    &.full-page {
        height: 100vh;
    }
}
