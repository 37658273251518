.primary-date-picker-container {
    width: 100%;

    .primary-date-picker {
        position: relative;

        .primary-select {
            background: #fff;
            padding-left: 10px;
            height: 38px;
            width: 100%;
            max-width: 100% !important;
            border: 1px solid #ddd;
            border-radius: 3px;
        }

        .primary-select-icon {
            background-color: #fff;
            color: #cccccc;
            height: 30px;
            width: 28px;
            position: absolute;
            top: 3px;
            z-index: 2;
            right: 4px;
            border-radius: 2px;
            box-shadow: 1px 1px 4px 0px #33000000;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                color:#b6b3b3
            }
        }


    }
}